import { useEffect } from "react";
import { SpacetimeDBClient, Identity } from "@clockworklabs/spacetimedb-sdk";
import Elements from "../module_bindings/elements";
import UpdateElementPosition from "../module_bindings/update_element_position_reducer";
import Heartbeat from "../module_bindings/heartbeat";
import KeepAlive from "../module_bindings/keep_alive_reducer";
import ResetElementPositionsReducer from "../module_bindings/reset_element_positions_reducer";
import AddWaitlistReducer from "../module_bindings/add_waitlist_reducer";

const useStDB = (setStdbInitialized: Function, setIdentity: Function) => {
  SpacetimeDBClient.registerTables(Elements,Heartbeat);
  SpacetimeDBClient.registerReducers(UpdateElementPosition, KeepAlive, ResetElementPositionsReducer, AddWaitlistReducer);

  const client = new SpacetimeDBClient(
    "wss://pogly.spacetimedb.com",
    "Pogly-Web-Landing"
  );

  client?.onConnect((token: string, Identity: Identity) => {
    console.log("Connected to StDB! [" + Identity.toHexString() + "]");
    setIdentity(Identity);
    client?.subscribe(["SELECT * FROM Elements", "SELECT * FROM Heartbeat"]);
  });

  client?.on("initialStateSync", () => {
    setStdbInitialized(true);
  });

  client?.onError((...args: any[]) => {
    console.log("ERROR", args);
  });

  useEffect(() => {
    client?.connect();
  }, []);
};

export default useStDB;
